import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/post.css'
import HeroImage from '../components/HeroImage/HeroImage'
import richTextOptions from '../shared/richTextOptions'

const Post = ({ pageContext, location }) => {
  const path = location.pathname
  const { contentful_id: contentfulID, title, heroImage, body, backLinkText, backUrl } = pageContext
  const linkText = backLinkText || 'Back'
  return (
    <Layout editPageId={contentfulID} path={path}>
      <SEO title={title} />
      <HeroImage src={heroImage.file.url} alt={title} title={title} isLargeImage />
      <div className="container post text-content">
        {backUrl && (
          <Link to={backUrl} className="back-link">
            &larr; {linkText}
          </Link>
        )}
        {documentToReactComponents(body.json, richTextOptions)}
      </div>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}
